exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-en-privacy-tsx": () => import("./../../../src/pages/cookies-en-privacy.tsx" /* webpackChunkName: "component---src-pages-cookies-en-privacy-tsx" */),
  "component---src-pages-gebruiksvoorwaarden-tsx": () => import("./../../../src/pages/gebruiksvoorwaarden.tsx" /* webpackChunkName: "component---src-pages-gebruiksvoorwaarden-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-random-tsx": () => import("./../../../src/pages/random.tsx" /* webpackChunkName: "component---src-pages-random-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/AuthorPage.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */)
}

